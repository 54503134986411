import React, {useState} from 'react';
import {navigate} from "gatsby";
import Layout from "../components/layout";
import {URL} from "../constants/urls";
import {STRINGS} from "../constants/strings";
import {validateEmail} from "../utils/articleUtil";
import {metadata} from "../../config";
import {Popup} from "@cg-squad/ui-components";

const EnergyBook = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tnc, setTnC] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const [loading, setLoading] = useState(false);

    const onActionClick = async (action) => {
        switch (action) {
            case 'yes':
                if(validateForm()) {
                    setError(null);
                    setLoading(true);
                    fetch(`${URL.SERVER}/subscribe?name=${name}&email=${email}&tag=Energy_Saving_Form&marketing=${marketing}&website=${STRINGS.SUBSCRIPTION_TAG}`)
                        .then(resp => resp.json())
                        .then(res => {
                            setLoading(false);
                            setShowPopup(true);
                        });
                }
                break;
        }
    }

    const validateForm = () => {
        if (!email || !validateEmail(email)) {
            setError('Please enter valid email');
            return false;
        }
        if (!tnc) {
            setError('Please accept Terms and Privacy Policy');
            return false;
        }
        return true;
    }

    const onClosePopup = () => {
        setShowPopup(false);
        navigate(metadata.url);
    }

    return (
        <Layout hideCategories={true}>
            {showPopup && <Popup onClose={onClosePopup} onOutSideClick={()=>{}}>
                <div className="lg:max-w-[500px] max-w-[300px]">
                    <h3 className="font-normal">
                        All set, your personalised download link is being setup and sent to your email as you read these words :-)
                    </h3>
                    <h4 style={{textAlign: 'center'}}>Please check your email now to get your free eBook edition.</h4>
                </div>
            </Popup>}
            <div className="wrapper grid lg:grid-cols-2 lg:gap-28 font-outfit">

                <div>
                    <h2 className="font-semibold text-7xl leading-none">Get our <span className="text-teal-550">Energy Saving Guide!</span></h2>
                    <p className="text-2xl lg:text-3xl leading-tight lg:pr-12" style={{maxWidth: '556px'}}>Learn how you can save significant sums on your energy bills with our handy and practical guide!</p>
                    <div className="mt-10">
                        <img src="/images/logos/logo-ag.svg" width={248} height={53} alt="AgeGroup logo"/>
                    </div>
                    <div className="mt-6">
                        <img src="/images/energy-book-image.png" width={630} height={339}/>
                    </div>
                </div>
                <div className="bg-white flex flex-col justify-between rounded-4xl border border-gray-120"
                     style={{padding: "60px 30px", color: "#083054", minHeight: "720px"}}>
                    <h3 className="text-4xl font-semibold" style={{maxWidth: '400px', color: "#021D35"}}>Type your personal details here</h3>
                    <div>
                        {error && <div className={`error-message text-red`}>{error}</div>}
                        <div className={'label text-xl mb-1'} style={{color: "#021D35"}}>Your name</div>
                        <input className="px-8 py-4 w-full border rounded-3xl"
                               type="text"
                               placeholder="Enter your name here*"
                               value={name}
                               onChange={(event) => {
                                   setError(null);
                                   setName(event.target.value);
                               }}/>
                        <div className={'label text-xl mt-8 mb-1'} style={{color: "#021D35"}}>Email address</div>
                        <input className="px-8 py-4 w-full border rounded-3xl"
                               type="email"
                               placeholder="Enter your email adress here*"
                               value={email}
                               onChange={(event) => {
                                   setError(null);
                                   setEmail(event.target.value)
                               }}/>
                        <div className="checkbox-container mt-8">
                            <input type="checkbox" id="tnc" defaultChecked={tnc} onChange={() => setTnC(!tnc)}/>
                            <label htmlFor="tnc" className="ml-2">
                                I have read and accept <a className="underline" href="/website-terms-of-use" target="_blank">
                                Terms</a> and <a className="underline" href="/website-privacy-policy" target="_blank">Privacy Policy</a>
                            </label>
                        </div>
                        <div className={'checkbox-container mt-2'}>
                            <input type="checkbox" id="marketing" defaultChecked={marketing} onChange={() => setMarketing(!marketing)}/>
                            <label htmlFor="marketing" className="ml-2 leading-none">
                                I’m happy for AgeGroup to send me content and selected marketing information which may be of interest to me.
                            </label>
                        </div>
                    </div>
                    <div className="action-container text-center mt-4">
                        <button onClick={() => {onActionClick('yes');}}
                                className="energy-book subscribe-button bg-red rounded-2xl py-4 text-white text-2xl"
                                style={{minWidth: "290px"}}
                                disabled={loading}>
                            CLICK HERE
                            {loading && (
                                <img
                                    src="/images/loading.svg"
                                    style={{ marginLeft: "5px" }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default EnergyBook
